import React, { Suspense, useState, useEffect } from 'react';
import { Container, Spinner } from 'reactstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import CONSTANTS from 'utils/CONSTANTS';
import ViewTable from 'utils/ReactTableCards';
import {
  deleteMedicineOrder,
  editMedicineOrder,
  getAllMedicineOrder,
} from 'utils/API/api';

import AlertPopup from 'components/alert-popup';
import { NotificationManager } from 'components/common/react-notifications';

const MedicineOrder = ({ match }) => {
  const [medicineOrderList, setMedicineOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteMedicineOrder = (clientId) => {
    setDeleteId(clientId);
    setIsConfirmationOpen(true);
  };

  const confirmDeleteMedicineOrder = async () => {
    if (deleteId) {
      const res = await deleteMedicineOrder(deleteId);
      if (res !== -1) {
        setRefresh((previous) => !previous);
        setIsConfirmationOpen(false);
      }
    }
  };
  const MEDICINE_ORDER_OPTIONS = [
    { id: 'Pending', label: 'Pending', value: 'pending' },
    { id: 'Accepted', label: 'Accepted', value: 'accepted' },
    { id: 'Dispatch', label: 'Dispatch', value: 'dispatch' },
    { id: 'Delivered', label: 'Delivered', value: 'delivered' },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);

      const res = await getAllMedicineOrder();
      if (res !== -1) {
        setMedicineOrderList(
          res?.data?.data.map((medicineOrderItem, index) => {
            return {
              no: index + 1,
              ...medicineOrderItem,
              medicineName: medicineOrderItem?.medicine?.name || '',

              deleteData: {
                buttonLabel: 'Delete',
                id: medicineOrderItem?.id,
                onClick: handleDeleteMedicineOrder,
              },
              status: {
                options: MEDICINE_ORDER_OPTIONS,
                defaultOption: medicineOrderItem?.status,
                onChange: (val) => {
                  console.log(val.target.value, 'selected buddy');
                  (async () => {
                    console.log(val);
                    if (medicineOrderItem?.id) {
                      let payload = {};
                      const tempValue = val.target.value;
                      if (tempValue) {
                        payload = { status: `${tempValue}` };
                      }

                      const respo = await editMedicineOrder(
                        medicineOrderItem?.id,
                        payload
                      );
                      if (respo !== -1) {
                        NotificationManager.success(
                          'Status Updated Successfully!',
                          'Success',
                          3000,
                          null,
                          ''
                        );
                      }
                    }
                  })();
                },
              },
            };
          })
        );
      }
      setLoading(false);
    })();
  }, [refresh]);

  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route
          path={`${match.url}`}
          exact
          render={() =>
            !loading ? (
              <>
                <Container
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    maxWidth: '100%',
                    margin: '0px',
                    padding: '0',
                    marginTop: '-10px',
                    marginBottom: '15px',
                    marginLeft: '-27px',
                  }}
                />

                <ViewTable
                  headers={CONSTANTS.TABLE_HEADER.MEDICINE_ORDER_LIST}
                  items={medicineOrderList}
                  advisorId={CONSTANTS.TABLE_ID.medicineOrder}
                  filterParams="medicineName"
                />

                <AlertPopup
                  isOpen={isConfirmationOpen}
                  positiveText="Yes"
                  negativeText="No"
                  warning="Are you sure you want to delete?"
                  onNegative={() => {
                    setIsConfirmationOpen(false);
                    setDeleteId(null);
                  }}
                  onPositive={confirmDeleteMedicineOrder}
                />
              </>
            ) : (
              <>
                <Container className="d-flex justify-content-center align-items-center">
                  <Spinner
                    animation="border"
                    className="d-inline-flex m-2 "
                    color="$theme-color-yellow-granola"
                  />
                </Container>
              </>
            )
          }
        />
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
};
export default MedicineOrder;
