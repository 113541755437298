import { NotificationManager } from 'components/common/react-notifications';
import { CURRANT_USER } from 'utils/CONSTANTS';
import Services from './service';
import authStorage from './authStroge';

const apiCall = async (
  endPoint,
  type,
  payload = {},
  errorMessage = { heading: '', message: '' }
) => {
  try {
    switch (type) {
      case 'post':
        return await Services.post(endPoint, payload);
      case 'delete':
        return await Services.delete(endPoint);
      case 'patch':
        return await Services.patch(endPoint, payload);
      default:
        return await Services.get(endPoint);
    }
  } catch (error) {
    console.log(error.response);
    if (error.response?.data) {
      NotificationManager.warning(
        error.response?.data?.errors,
        '',
        3000,
        null,
        null,
        ''
      );
      return -1;
    }
    if (
      error?.response?.data?.message === 'User not authorized' &&
      endPoint.includes('/admin/getMe')
    ) {
      authStorage.deleteAuthDetails();
      localStorage.removeItem('authUser');
      localStorage.removeItem('token');
      window.location.reload();
    }

    NotificationManager.warning(
      errorMessage.message !== ''
        ? errorMessage.message
        : error?.response?.data?.message,
      errorMessage.heading !== '' ? errorMessage.heading : '',
      3000,
      null,
      null,
      ''
    );
    return -1;
  }
};

// *********************** Dashboard ****************************

export const getAllCallsDetails = () => apiCall('/admins/calls', 'get');

export const getAnalytics = (date) =>
  apiCall(
    `/organizations/analytics/organizations/${CURRANT_USER.user.id}${date}`,
    'get'
  );
export const getAnalyticsID = () =>
  apiCall(
    `/organizations/analytics/organizations/${CURRANT_USER.user.id}`,
    'get'
  );

export const getCallHistory = () =>
  apiCall(`/organizations/calls/history/${CURRANT_USER.user.id}`, 'get');

export const getChatHistory = () =>
  apiCall(`/organizations/chats/history/${CURRANT_USER.user.id}`, 'get');

// *********************** Login ****************************
export const organizationLogin = (payload) =>
  apiCall('/admins/login', 'post', payload);

export const getAdmin = () =>
  apiCall(`/signatories/organizations/${CURRANT_USER?.user?.id}`, 'get');

export const getOrganization = () => apiCall('/organizations/my/users', 'get');

export const getKycRequest = () => apiCall(`/kycs/admins`, 'get');

export const getSpecializations = (currentAdvisor) =>
  apiCall(`/data/specializations?expertise=${currentAdvisor}`, 'get');

export const addSpecializations = (payload) =>
  apiCall('/data/Specializations', 'post', payload);

export const deleteSpecializations = (id) =>
  apiCall(`/data/specializations/${id}`, 'delete');

export const getReport = () => apiCall('/admins/reports', 'get');

// *********************** User ****************************
export const getAllAdvisors = (category) =>
  apiCall(`/admins/advisors/?expertise=${category}`, 'get');
export const updateOrganizationAction = (id) =>
  apiCall(`/organizations/block/users/${id}`, 'patch');

export const updateUserAction = (id) =>
  apiCall(`/admins/users/${id}/block`, 'patch');

export const addUser = (payload) =>
  apiCall(`/organizations/users/add`, 'post', payload);

export const removeUser = (id) =>
  apiCall(`/organizations/remove/users/${id}`, 'patch');

// *********************** Advisor ****************************
export const addAdvisor = (payload) =>
  apiCall('/advisors/singup', 'post', payload);

export const updateAdvisorAction = (id) =>
  apiCall(`/admins/advisors/${id}/block`, 'patch');

export const updateSpecialization = (payload) =>
  apiCall('/data/specializations', 'patch', payload);

export const addAdmin = (payload) => apiCall('/admins/', 'post', payload);

export const deleteAdmin = (id) => apiCall(`/admins/${id}`, 'delete');

export const approveRejectKyc = (id, payload) =>
  apiCall(`/kycs/${id}/admins`, 'patch', payload);

export const getTaxCharges = (currentAdvisorTax) =>
  apiCall(`/admins/charges/${currentAdvisorTax}`, 'get');

export const setTaxCharges = (taxId, payload) =>
  apiCall(`/admins/charges/${taxId}`, 'patch', payload);

export const getAdvisorTransaction = () =>
  apiCall('/admins/transactions/advisors', 'get');

export const getAllUsers = async () => {
  try {
    const data = await Services.get('/users');
    return data;
  } catch (error) {
    console.log(error, 'error');
    return 0;
  }
};

// ************* Coupon **********************
export const getCoupon = () => apiCall(`/coupons`, 'get');
export const deleteCoupon = (couponId) =>
  apiCall(`/coupons/${couponId}`, 'delete');

export const addCoupon = (body) => apiCall(`/coupons`, 'post', body);
export const getCouponDetails = (Id) =>
  apiCall(`/coupons/subscibed/users/${Id}`, 'get');

// ************* poster **********************
export const getPoster = () => apiCall(`/posters`, 'get');
export const addPoster = (body) => apiCall(`/posters`, 'post', body);
export const deletePoster = (posterId) =>
  apiCall(`/posters/${posterId}`, 'delete', {
    heading: 'Poster Delete Error',
    message: 'Something went wrong, Please try again',
  });

// *********************** Text-Charge ****************************
export const userCategoryImg = () => apiCall(`/users/category/imgs`, 'get');

export const updateTextandCharges = (id, body) => {
  return apiCall(`/admins/charges/${id}`, 'patch', body);
};

// *********************** Call Session ****************************

export const getCallSession = () => apiCall(`/call-sessions`, 'get');

export const addCallSession = (body) => apiCall(`/call-sessions`, 'post', body);

export const deleteCallSession = (id) =>
  apiCall(`/call-sessions/${id}`, 'delete');

// *********************** Advisors ****************************

export const getAdvisors = () =>
  apiCall(`/admins/advisors/?expertise=Lawyer`, 'get');

// *********************** Profile ****************************

export const getProfile = () => apiCall(`/organizations/profile`, 'get');

export const updateProfile = (payload) =>
  apiCall(`/organizations/profile`, 'patch', payload);

// *********************** Project ****************************
export const getAllProject = () => apiCall(`/project`, 'get');
export const addProject = (payload) => apiCall(`/project`, 'post', payload);
export const editProject = (id, payload) =>
  apiCall(`/project/${id}`, 'patch', payload);
export const getProjectDetail = (id) => apiCall(`/project/${id}`, 'get');

// *********************** Project => Phase ****************************

export const getAllPhase = () => apiCall(`/phase`, 'get');
export const addPhase = (payload) => apiCall(`/phase`, 'post', payload);
export const editPhase = (id, payload) =>
  apiCall(`/phase/${id}`, 'patch', payload);
export const deletePhase = (id) => apiCall(`/phase/${id}`, 'delete');

// *********************** Project => Asset ****************************

export const getAllAsset = () => apiCall(`/projectAssets`, 'get');
export const addAsset = (payload) => apiCall(`/projectAssets`, 'post', payload);
export const editAsset = (id, payload) =>
  apiCall(`/projectAssets/${id}`, 'patch', payload);
export const deleteAsset = (id) => apiCall(`/projectAssets/${id}`, 'delete');

// *********************** Project => Extra Work ****************************

export const getAllExtraWork = () => apiCall(`/extraWork`, 'get');
export const addExtraWork = (payload) => apiCall(`/extraWork`, 'post', payload);
export const editExtraWork = (id, payload) =>
  apiCall(`/extraWork/${id}`, 'patch', payload);
export const deleteExtraWork = (id) => apiCall(`/extraWork/${id}`, 'delete');

// *********************** Project => Developer ****************************

export const getAllProjectDeveloper = () => apiCall(`/projectDeveloper`, 'get');
export const addProjectDeveloper = (payload) =>
  apiCall(`/projectDeveloper`, 'post', payload);
export const editProjectDeveloper = (id, payload) =>
  apiCall(`/projectDeveloper/${id}`, 'patch', payload);
export const deleteProjectDeveloper = (id) =>
  apiCall(`/projectDeveloper/${id}`, 'delete');

// *********************** Doctor ****************************

export const addDoctor = (payload) =>
  apiCall(`/admin/doctor/signup`, 'post', payload);
export const getAllDoctor = () => apiCall(`/doctor`, 'get');
export const deleteDoctor = (id) => apiCall(`/admin/doctor/${id}`, 'delete');
export const editDoctor = (id, payload) =>
  apiCall(`/admin/doctor/${id}`, 'patch', payload);
export const getDoctorDetail = (id) => apiCall(`/doctor/${id}`, 'get');

// *********************** Notification ****************************
export const addNotification = (payload) =>
  apiCall(`/admin/addPersonalNotification`, 'post', payload);

export const addGroupNotification = (payload) =>
  apiCall(`/admin/addNotificationToTopic`, 'post', payload);

// *********************** Patient ****************************

export const addPatient = (payload) =>
  apiCall(`/patient/signup`, 'post', payload);
export const getAllPatient = () => apiCall(`/admin/patient`, 'get');
export const deletePatient = (id) => apiCall(`/patient/${id}`, 'delete');
export const editPatient = (id, payload) =>
  apiCall(`/admin/patient/${id}`, 'patch', payload);
export const getPatientDetail = (id) => apiCall(`/admin/patient/${id}`, 'get');

// *********************** Developer  => Note ****************************

export const addDeveloperNote = (payload) =>
  apiCall(`/developerNote`, 'post', payload);
export const getAllDeveloperNote = () => apiCall(`/developerNote`, 'get');
export const deleteDeveloperNote = (id) =>
  apiCall(`/developerNote/${id}`, 'delete');
export const editDeveloperNote = (id, payload) =>
  apiCall(`/developerNote/${id}`, 'patch', payload);

// *********************** Developer  => Increment ****************************

export const addDeveloperIncrement = (payload) =>
  apiCall(`/developerInc`, 'post', payload);
export const getAllDeveloperIncrement = () => apiCall(`/developerInc`, 'get');
export const deleteDeveloperIncrement = (id) =>
  apiCall(`/developerInc/${id}`, 'delete');
export const editDeveloperIncrement = (id, payload) =>
  apiCall(`/developerInc/${id}`, 'patch', payload);

// *********************** Developer  => Leave ****************************

export const addDeveloperLeave = (payload) =>
  apiCall(`/developerLeave`, 'post', payload);
export const getAllDeveloperLeave = () => apiCall(`/developerLeave`, 'get');
export const deleteDeveloperLeave = (id) =>
  apiCall(`/developerLeave/${id}`, 'delete');
export const editDeveloperLeave = (id, payload) =>
  apiCall(`/developerLeave/${id}`, 'patch', payload);

// *********************** Client ****************************

export const addClient = (payload) => apiCall(`/client`, 'post', payload);
export const getAllClient = () => apiCall(`/client`, 'get');
export const deleteClient = (id) => apiCall(`/client/${id}`, 'delete');
export const editClient = (id, payload) =>
  apiCall(`/client/${id}`, 'patch', payload);
export const getClientDetail = (id) => apiCall(`/client/${id}`, 'get');

// *********************** Transaction ****************************

export const addTransaction = (payload) =>
  apiCall(`/transaction`, 'post', payload);
export const getAllTransaction = () => apiCall(`/transaction`, 'get');
export const deleteTransaction = (id) =>
  apiCall(`/transaction/${id}`, 'delete');
export const editTransaction = (id, payload) =>
  apiCall(`/transaction/${id}`, 'patch', payload);

// *********************** Lead ****************************

export const addLead = (payload) => apiCall(`/lead`, 'post', payload);
export const getAllLead = () => apiCall(`/lead`, 'get');
export const deleteLead = (id) => apiCall(`/lead/${id}`, 'delete');
export const editLead = (id, payload) =>
  apiCall(`/lead/${id}`, 'patch', payload);

// *********************** Quotation ****************************

export const addQuotation = (payload) => apiCall(`/quotation`, 'post', payload);
export const getAllQuotation = () => apiCall(`/quotation`, 'get');
export const deleteQuotation = (id) => apiCall(`/quotation/${id}`, 'delete');
export const editQuotation = (id, payload) =>
  apiCall(`/quotation/${id}`, 'patch', payload);

// *********************** Developer Hiring ****************************

export const addDeveloperHiring = (payload) =>
  apiCall(`/developerHiring`, 'post', payload);
export const getAllDeveloperHiring = () => apiCall(`/developerHiring`, 'get');
export const deleteDeveloperHiring = (id) =>
  apiCall(`/developerHiring/${id}`, 'delete');
export const editDeveloperHiring = (id, payload) =>
  apiCall(`/developerHiring/${id}`, 'patch', payload);

// *********************** Dashboard ****************************

export const getDashboardAnalytics = () => apiCall(`/analytics`, 'get');

// *********************** GIRNAR ****************************

// *********************** Login ****************************
export const adminLogin = (payload) => apiCall('/admin/login', 'post', payload);
export const getCurrentAdmin = () => apiCall('/admin/getMe', 'get');
export const adminSignUp = (payload) =>
  apiCall('/admin/signup', 'post', payload);
export const adminForgetPassword = (payload) =>
  apiCall('/auth/forgotpassword', 'post', payload);
export const adminUpdatePassword = (payload) =>
  apiCall('/auth/updatepassword', 'post', payload);

// *********************** Application ****************************

export const addApplication = (payload) => apiCall(`/app`, 'post', payload);
export const getAllApplication = () => apiCall(`/app`, 'get');
export const deleteApplication = (id) => apiCall(`/app/${id}`, 'delete');
export const editApplication = (id, payload) =>
  apiCall(`/app/${id}`, 'patch', payload);

// *********************** Plan ****************************

export const addPlan = (payload) => apiCall(`admin/plan`, 'post', payload);
export const getAllPlan = () => apiCall(`admin/plan`, 'get');
export const deletePlan = (id) => apiCall(`admin/plan/${id}`, 'delete');
export const editPlan = (id, payload) =>
  apiCall(`admin/plan/${id}`, 'patch', payload);

// *********************** Speciality ****************************

export const addSpeciality = (payload) =>
  apiCall(`admin/speciality`, 'post', payload);
export const getAllSpeciality = () => apiCall(`admin/speciality`, 'get');
export const deleteSpeciality = (id) =>
  apiCall(`admin/speciality/${id}`, 'delete');
export const editSpeciality = (id, payload) =>
  apiCall(`admin/speciality/${id}`, 'patch', payload);

// *********************** Medicine ****************************

export const addMedicine = (payload) => apiCall(`/medicine`, 'post', payload);
export const getAllMedicine = () => apiCall(`/medicine`, 'get');
export const deleteMedicine = (id) => apiCall(`/medicine/${id}`, 'delete');
export const editMedicine = (id, payload) =>
  apiCall(`/medicine/${id}`, 'patch', payload);

// *********************** Medicine Order ****************************

export const addMedicineOrder = (payload) =>
  apiCall(`/medicineOrder`, 'post', payload);
export const getAllMedicineOrder = () => apiCall(`/medicineOrder`, 'get');
export const deleteMedicineOrder = (id) =>
  apiCall(`/medicineOrder/${id}`, 'delete');
export const editMedicineOrder = (id, payload) =>
  apiCall(`/medicineOrder/${id}`, 'patch', payload);

// *********************** Order ****************************

export const addOrder = (payload) => apiCall(`/order`, 'post', payload);
export const getAllOrder = () => apiCall(`/admin/order`, 'get');
export const deleteOrder = (id) => apiCall(`/order/${id}`, 'delete');
export const editOrder = (id, payload) =>
  apiCall(`/order/${id}`, 'patch', payload);

// *********************** Complaint ****************************

export const getAllComplaint = () => apiCall(`/admin/complain`, 'get');
export const deleteComplaint = (id) =>
  apiCall(`/admin/complain/${id}`, 'delete');

// *********************** FAQ ****************************

export const addFaq = (payload) => apiCall(`/admin/FAQS`, 'post', payload);
export const getAllFaq = () => apiCall(`/admin/FAQs`, 'get');
export const deleteFaq = (id) => apiCall(`/admin/FAQs/${id}`, 'delete');
export const editFaq = (id, payload) =>
  apiCall(`/admin/FAQs/${id}`, 'patch', payload);

// *********************** Feedback ****************************

export const addFeedback = (payload) =>
  apiCall(`/admin/report`, 'post', payload);
export const getAllFeedback = () => apiCall(`/admin/report`, 'get');
export const deleteFeedback = (id, PID) =>
  apiCall(`/admin/report/${id}?patientId=${PID}`, 'delete');
export const editFeedback = (id, PID, payload) =>
  apiCall(`/admin/report/${id}?patientId=${PID}`, 'patch', payload);

// *********************** Lab Test ****************************

export const addLabTest = (payload) =>
  apiCall(`/admin/labTest`, 'post', payload);
export const getAllLabTest = () => apiCall(`/admin/labTest`, 'get');
export const deleteLabTest = (id) => apiCall(`/admin/labTest/${id}`, 'delete');
export const editLabTest = (id, payload) =>
  apiCall(`/admin/labTest/${id}`, 'patch', payload);

// *********************** Symptoms ****************************

export const addSymptoms = (payload) =>
  apiCall(`/admin/symptoms`, 'post', payload);
export const getAllSymptoms = () => apiCall(`/admin/symptoms`, 'get');
export const deleteSymptoms = (id) =>
  apiCall(`/admin/symptoms/${id}`, 'delete');
export const editSymptoms = (id, payload) =>
  apiCall(`/admin/symptoms/${id}`, 'patch', payload);

// *********************** Doctor Availability (Weekly slot) ****************************

export const addDoctorAvailability = (payload) =>
  apiCall(`/admin/weeklySlot`, 'post', payload);
export const getAllDoctorAvailability = (dId) =>
  apiCall(`/admin/weeklySlot?doctorId=${dId}`, 'get');
export const deleteDoctorAvailability = (id, dId) =>
  apiCall(`/admin/weeklySlot/${id}?doctorId=${dId}`, 'delete');
export const editDoctorAvailability = (id, dId, payload) =>
  apiCall(`/admin/weeklySlot/${id}?doctorId=${dId}`, 'patch', payload);

// *********************** Doctor Certificate****************************

export const addDoctorCertificate = (payload) =>
  apiCall(`/admin/awards`, 'post', payload);
export const getAllDoctorCertificate = () => apiCall(`/admin//awards`, 'get');
export const deleteDoctorCertificate = (id, dId) =>
  apiCall(`/admin/awards/${id}?doctorId=${dId}`, 'delete');
export const editDoctorCertificate = (id, dId, payload) =>
  apiCall(`/admin/awards/${id}?doctorId=${dId}`, 'patch', payload);

// *********************** Doctor Published Papers ****************************

export const addDoctorPublishedPapers = (payload) =>
  apiCall(`/admin/publishedpapers`, 'post', payload);
export const getAllDoctorPublishedPapers = (id) =>
  apiCall(`/admin/publishedpapers?doctorId=${id}`, 'get');
export const deleteDoctorPublishedPapers = (id, DId) =>
  apiCall(`/admin/publishedpapers/${id}?doctorId=${DId}`, 'delete');
export const editDoctorPublishedPapers = (id, DId, payload) =>
  apiCall(`/admin/publishedpapers/${id}?doctorId=${DId}`, 'patch', payload);

// *********************** Doctor Management Feedback ****************************

export const addDoctorManagementFeedback = (payload) =>
  apiCall(`/admin/managementFeedback`, 'post', payload);
export const getAllDoctorManagementFeedback = (id) =>
  apiCall(`/admin/managementFeedback?doctorId=${id}`, 'get');
export const deleteDoctorManagementFeedback = (id, DID) =>
  apiCall(`/admin/managementFeedback/${id}?doctorId=${DID}`, 'delete');
export const editDoctorManagementFeedback = (id, payload) =>
  apiCall(`/admin/managementFeedback/${id}`, 'patch', payload);

// *********************** Appointment  ****************************
export const getAllAppointment = () => apiCall(`/patientVisitor`, 'get');
export const editAppointment = (id, payload) =>
  apiCall(`/patientVisitor/${id}`, 'patch', payload);
export const visiterEditPage = (id, payload) =>
  apiCall(`/patientVisitor/${id}`, 'patch', payload);

// *********************** Patient Address ****************************

export const addPatientAddress = (payload) =>
  apiCall(`/admin/patientAddress`, 'post', payload);
export const getAllPatientAddress = (id) =>
  apiCall(`/admin/patientAddress?patientId=${id}`, 'get');
export const deletePatientAddress = (id, PID) =>
  apiCall(`/admin/patientAddress/${id}?patientId=${PID}`, 'delete');
export const editPatientAddress = (id, PID, payload) =>
  apiCall(`/admin/patientAddress/${id}?patientId=${PID}`, 'patch', payload);

// *********************** Patient Feedback ****************************

export const addPatientFeedback = (payload) =>
  apiCall(`/admin/patientFeedback`, 'post', payload);
export const getAllPatientFeedback = (id) =>
  apiCall(`/admin/patientFeedback?patientId=${id}`, 'get');
export const deletePatientFeedback = (id, PID) =>
  apiCall(`/admin/patientFeedback/${id}?patientId=${PID}`, 'delete');
export const editPatientFeedback = (id, payload) =>
  apiCall(`/admin/patientFeedback/${id}`, 'patch', payload);

// *********************** Patient Emergency Contact ****************************

export const addPatientEmergencyContact = (payload) =>
  apiCall(`/admin/emergencyContact`, 'post', payload);
export const getAllPatientEmergencyContact = () =>
  apiCall(`/admin/emergencyContact`, 'get');
export const deletePatientEmergencyContact = (id, patientId) =>
  apiCall(`/admin/emergencyContact/${id}?patientId=${patientId}`, 'delete');
export const editPatientEmergencyContact = (id, payload, patientId) =>
  apiCall(
    `/admin/emergencyContact/${id}?patientId=${patientId}`,
    'patch',
    payload
  );
// *********************** Patient Report ****************************
export const addPatientReport = (payload) =>
  apiCall(`/admin/report`, 'post', payload);
export const getAllPatientReports = () => apiCall(`/admin/report`, 'get');
export const getAllPatientReportsviaId = (patientId) =>
  apiCall(`/admin/report/?patientId=${patientId}`, 'get');
export const deletePatientReport = (id, patientId) =>
  apiCall(`/admin/report/${id}?patientId=${patientId}`, 'delete');
export const editPatientReport = (id, payload, patientId) =>
  apiCall(`/admin/report/${id}?patientId=${patientId}`, 'patch', payload);
// *********************** Generate Api Key ****************************

export const generateApiKey = () => apiCall(`/client/generateKey`, 'get');

// *********************** Assesment ****************************
export const addAssesment = (payload) =>
  apiCall(`/admin/assessment`, 'post', payload);
export const getAllAssesment = () => apiCall(`/admin/assessment`, 'get');
export const editAssesment = (id, payload) =>
  apiCall(`/admin/assessment/${id}`, 'patch', payload);
export const deleteAssesment = (id) =>
  apiCall(`/admin/assessment/${id}`, 'delete');
